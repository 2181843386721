<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          Crop2ML: A centralized framework to exchange and reuse model components between crop modeling platforms
        </h1>
      </b-col>
    </b-row>
    <b-row style="margin-top: 10px">
      <b-col>
        <b-img
            src="images/crop2ml.png"
            fluid
        />
      </b-col>
      <b-col>
        <p style="text-align: justify; margin: 50px;">
          <a
              target="_blank"
              href="https://crop2ml.readthedocs.io/en/latest/?badge=latest"
              class="text-decoration-none">Crop2ML</a> (Crop Modelling Meta Language) is a centralized framework
          proposed
          by the
          Agricultural Model Exchange Initiative (<a href="#/AMEI/Vision">AMEI</a>) to exchange and reuse soil, plant
          and crop process-based model
          components between modeling platforms.</p>
        <p style="text-align: justify; margin: 50px;">Crop2ML does not aim at replacing existing modeling platforms or
          at simulating
          components within large
          modeling solutions (crop models), rather Crop2ML provides a solution that generates components, from a
          metalanguage, from and for specific modeling platforms.</p>
      </b-col>
      <p style="text-align: justify; margin: 50px;">Crop2ML separates the structure of a model component from its
        implementation. It is based on
        a declarative
        architecture of modular model representation to describe the biophysical processes and their transformation to
        model components that conform to crop modeling platforms.
      </p>


    </b-row>
    <b-row>
      <b-col>
        <b-list-group>
          <div class="d-flex w-100 align-items-start">
            <h5 class="mb-1">Crop2ML is designed following FAIR principles for research software to provide:</h5>
          </div>
          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Simplicity</h5>
            </div>

            <p class="text-left mb-1">
              Model specifications are defined using the declarative language XML (eXtensible Markup Language) with
              generic concepts shared between crop modeling platforms and model algorithms (that is the description of
              the flow of instructions from inputs to outputs including mathematical expressions) are encoded using a
              minimal language (Cython Meta Langage, <a href="#/Crop2ML/WhatIsCyML">CyML</a>).
            </p>

          </b-list-group-item>

          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Transparency</h5>
            </div>

            <p class="text-left mb-1">
              Models are shared as documented components in a well-defined format (Crop2ML format).
            </p>

          </b-list-group-item>

          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Flexibility</h5>
            </div>

            <p class="text-left mb-1">
              Model units are composed with a shared abstract representation of model structure.
            </p>

          </b-list-group-item>

          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Findability</h5>
            </div>

            <p class="text-left mb-1">
              Model specifications include rich metadata and are assigned a globally unique and persistent identifier
              for each released version.
            </p>

          </b-list-group-item>

          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Reusability</h5>
            </div>

            <p class="text-left mb-1">
              Model components are transformed into crop model platform compliant code to support efficient
              interoperability.
            </p>

          </b-list-group-item>

          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Reproducibility</h5>
            </div>

            <p class="text-left mb-1">
              Model components can be executed and tested regardless of the crop model platforms.
            </p>

          </b-list-group-item>

          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Modularity</h5>
            </div>

            <p class="text-left mb-1">
              Three levels of modularity of models are defined: (single) model units (ModelUnits), composite models
              (CompositeModels) and model package. Model packages contain model units and composite as well as data and
              documentation. They provide the flexibility to make different compositions based on these models.
            </p>

            <b-img
                src="images/modularity_of_models.png"
                fluid
            />
            <br>
            <small class="text-muted">Crop2ML concepts for ModelUnit and ModelComposite specifications</small>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <p>Crop2ML proposes an open framework (<a href="#/Crop2ML/Tools">PyCrop2ML</a>) to manage all the steps of model
      lifecycle.</p>

  </div>

</template>
<script>


export default {
  name: 'Crop2ML_WhatIsCrop2ML',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
