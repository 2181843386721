<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          CyML: A common modeling language of biophysical processes in crop models
        </h1>
        <b-embed
            type="iframe"
            aspect="16by9"
            src="videos/Reuse_of_process-based_models.mp4"
            allowfullscreen
        ></b-embed>
      </b-col>
    </b-row>
    <b-row style="margin-top: 20px">
      <b-col>
        <p style="text-align: justify; margin: 50px;">
          The CyML (Cython Meta Langage) source code is the common representation for model algorithms shared by the
          supported languages and platforms. CyML is a domain specific language that defines a set of common features
          resulting from the intersection of the programming languages supported by crop modeling platforms to propose a
          shared modeling language.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-img
            src="images/cyml_cython.png"
            fluid
        />

      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p style="text-align: center">
          From the intersection of a set of languages features to a definition of an abstract
          language CyML, defined as a subset of Cython language
        </p>
      </b-col>
    </b-row>
    <b-row style="margin-top: 20px">
      <b-col>
        <p style="text-align: justify; margin: 50px;">
          Crop2ML framework provides a source-to-source transformation system (CyMLT) which converts CyML source code
          into procedural (Fortran, Python, C++), object-oriented (Java, C#, C++, Python) and scripting or functional
          (R, Python) languages. The generated code is independent from the transformation system and can be run outside
          the Crop2ML framework. CyMLT integrates model documentation based on the model specification into generated
          code.
        </p>
      </b-col>
    </b-row>
    <b-row style="margin-top: 20px">
      <b-col>
        <p style="text-align: justify; margin: 50px;">
          The structure and syntax of CyML, as well as its transformation system to various languages and platforms is
          detailed in <a
              target="_blank"
              href="https://doi.org/10.1016/j.envsoft.2021.105055"
              class="text-decoration-none">Midingoyi et al. (2021)</a>. On-ongoing developments include the extension of CyMLT to transform
          source code in the platform’s languages to CyML. The next version of CyMLT (called CyMLTx) will thus resolve a
          many-to-many relationship, which will allow the automatic transformation of model algorithms source code
          between crop modeling platforms.
        </p>
      </b-col>
    </b-row>
  </div>

</template>
<script>


export default {
  name: 'Crop2ML_WhatIsCyML',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
