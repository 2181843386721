<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          Privacy Notice
        </h1>

        <p style="text-align: justify;">
          This Privacy Notice is based on the EMBL-EBI’s privacy notice. It explains what personal data are collected by
          the service you are requesting on https://crop2ml.org, for what purposes, how they are processed, and how we
          ensure their security.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          1. Who controls your personal data and how to contact us?
        </h3>
        <p style="text-align: justify;">
          The data controller’s contact details are the following and should be used only for data protection queries:
        </p>
        <div class="col-md-4 ">
          <address>
            <strong>INRAE UMR LEPSE</strong><br>
            2 place viala<br>
            34060 Montpellier<br>
            France<br>
            <a href="mailto:#">Email: umr-lepse-dir@inrae.fr</a>
          </address>
        </div>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          2. Which is the legal basis for processing your personal data?
        </h3>
        <p style="text-align: justify;">
          Processing your personal data is necessary for our legitimate interest of allowing the day-to-day management,
          operation and functioning of https://crop2ml.org.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          3. What personal data do we collect?
        </h3>
        <b-list-group flush>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">We collect the following personal data:</h5>
                </div>
              </div>
            </div>
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  IP addresses
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Date and time of a visit to the service website
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Operating system
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Amount of data transmitted
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Browser
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Email (only when support is requested by the user)
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          4. How do we use this personal data?
        </h3>
        <b-list-group flush>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">We will use the personal data to:</h5>
                </div>
              </div>
            </div>
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Provide the user access to the service
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Better understand the needs of the users and guide future improvements of the service
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Check that the Terms of Use of the service are followed
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Conduct and monitor data protection activities
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Create anonymous usage statistics
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Conduct and monitor security activities
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Answer a support request sent by the user
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          5. Who has access to your personal data?
        </h3>
        <b-list-group flush>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">Your personal data are disclosed to:</h5>
                </div>
              </div>
            </div>
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Authorized INRAE staff
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Service providers which INRAE relies on to provide the service
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          6. Will your personal data be transferred to third countries (i.e. countries not part of EU/EAA) and/or
          international organizations?
        </h3>
        <b-list-group flush>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">Your personal data are transferred to the following service provider based in a third
                    country that the service relies:</h5>
                </div>
              </div>
            </div>
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Google Analytics
                </p>
              </b-list-group-item>
              <p>There are no personal data transfers to international organizations.</p>
            </b-list-group>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          7. How long do we keep your personal data?
        </h3>
        <p style="text-align: justify;">
          Any personal data directly obtained from you will be retained as long as the service is live, even if you stop
          using the service. We will keep the personal data for the minimum amount of time possible to ensure legal
          compliance and to facilitate internal and external audits if they arise.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          8. Your rights regarding your personal data
        </h3>
        <b-list-group flush>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">You have the right to:</h5>
                </div>
              </div>
            </div>
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  1. Not be subject to decisions based solely on an automated processing of data (i.e. without human
                  intervention) without you having your views taken into consideration.
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  2. Request at reasonable intervals and without excessive delay or expense, information about the personal
                  data processed about you. Under your request we will inform you in writing about, for example, the
                  origin of the personal data or the preservation period.
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  3. Request information to understand data processing activities when the results of these activities are
                  applied to you.
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  4. Object at any time to the processing of your personal data unless we can demonstrate that we have
                  legitimate reasons to process your personal data.
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  5. Request free of charge and without excessive delay rectification or erasure of your personal data if
                  we have not been processing it respecting the INRAE Internal Policy for Data Protection.
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
        </b-list-group>
        <b-list-group flush>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">It must be clarified that rights 4 and 5 are only available whenever the processing
                    of your personal data is not necessary to:</h5>
                </div>
              </div>
            </div>
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Comply with a legal obligation
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Perform a task carried out in the public interest
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Exercise authority as a data controller
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Archive for purposes in the public interest, or for historical research purposes, or for statistical
                  purposes
                </p>
              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <p class="text-left mb-1">
                  Establish, exercise or defend legal claims
                </p>
              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>

</template>
<script>


export default {
  name: 'PrivacyNotice',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
