<template>

  <div v-if="$store.getters.getDataAreLoaded" style="display:block; margin-bottom:100px" class="mx-auto col-lg-7" >

    <center style="margin-top:20px;"><b>
      Welcome to Crop2ML, a repository of biophysical processes involved in crop growth and development.
      Find relevant crop models in the catalog below or update your own models.
    </b></center>

    <VoerroTagsInput element-id="tags"
      v-model="searchKeywordsTransform"
      :existing-tags="allKeywords"
      placeholder="Enter keywords to search through the catalog."
      :only-existing-tags="true"
      :typeahead="true"
      typeahead-style="badges"
      :typeahead-hide-discard="true"
      id-field="value"
      text-field="value">
    </VoerroTagsInput>

    <b-form-checkbox v-model="showOnlyPersoModels" switch style="margin-top: 1em; text-align: left;">
      Show only models which I am editor or administrator.
    </b-form-checkbox>
    <b-form-checkbox v-model="sortByName" switch style="margin-top: 1em; text-align: left;">
      Sort by Model Identifier
    </b-form-checkbox>

    <b-card v-for="m in filteredModels" v-bind:key="m.id" style="margin-top: 1em;"
        :header="m.id"
        header-bg-variant="secondary"
        header-text-variant="white"
        class="text-left"
        @click="$router.push({name: 'Model', params: { modelid : m.id }})"
    > 
      <b-row no-gutters>
          <b-col lg="3" >
              <b-card-img :src="getPicturePath(m.picture)" style="max-width:100px" alt="Model Preview" ></b-card-img>
          </b-col>
          <b-col lg="9" class="text-left">
              <h4> {{ m.versions[m.versions.length -1].Attributs.name }}</h4>
              <p>
                {{ m.versions[m.versions.length -1].Description.ShortDescription }}
              </p>
              <p class="caption">Model ID : {{m.versions[m.versions.length -1].AddedMetadata.identifiersCompactId}} | Submitter : {{m.versions[m.versions.length -1].AddedMetadata.uploaderUsername}} | Uploaded on : {{ formatDate(m.createdAt) }} | Last modified on : {{ formatDate(m.updatedAt) }}</p>
          </b-col>
      </b-row>

    </b-card>

  </div>
  
</template>
<script>

// import StarRating from 'vue-star-rating'
import config from '../config'
import VoerroTagsInput from '@voerro/vue-tagsinput';

export default {
  name: 'Repository',

  components: {
    // StarRating,
    VoerroTagsInput
  },

  data() {
    return {
      models: null,
      
      currentRating: "No Rating",
      currentSelectedRating: "No Current Rating",
      
      showOnlyPersoModels: false,
      searchKeywords: [],
      allKeywords: [],
      sortByName: false,
    }
  },

  async created() {
  },

  async mounted() {
    if (!this.$store.getters.getDataAreLoaded) {
      await this.$store.dispatch('initModels');
    }
    this.models = Array.from((this.$store.getters.getModels).values())
    this.searchKeywords = this.$store.getters.getCatalogFilters
    this.allKeywords = this.$store.getters.getKeywords
  },

  computed:{

    searchKeywordsTransform: {
      get: function () {
        return this.searchKeywords;
      },
      set: function (words) {
        this.searchKeywords = words
        this.$store.commit('setCatalogFilters', this.searchKeywords)
      }
    },

    filteredModels() {
      let filteredModels
      if (!this.models)
        return []
      if (this.searchKeywords.length != 0) {
        let keywordsFormatted = []
        for (const k of this.searchKeywords) {
          keywordsFormatted.push(k.value)
        }
        filteredModels = this.models.filter(m => {
          let match = false
          m.versions.forEach(v => {
            if (v.AddedMetadata.keywords.some(k => keywordsFormatted.indexOf(k) >= 0))
              match = true
          })
          return match
        })
      } else {
        filteredModels = this.models
      }
      if (this.showOnlyPersoModels) {
        filteredModels = filteredModels.filter(m => this.isAdmin(m) || this.isEditor(m))
      }
      if (this.sortByName){
        return filteredModels.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
      } else {
        return filteredModels.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
      }
    },

  },

  methods: {

    isAdmin(model){
      return (this.$store.getters.getUser != null &&
      model.administratorsUsernames.includes(this.$store.getters.getUser.userName))
      //this.$store.getters.getUser.associatedModels.findIndex(m => (m.modelId == this.model.id && m.role == "administrator")) != -1)
    },

    isEditor(model){
      return (this.$store.getters.getUser != null &&
      model.editorsUsernames.includes(this.$store.getters.getUser.userName))
      //this.$store.getters.getUser.associatedModels.findIndex(m => (m.modelId == this.model.id && m.role == "editor")) != -1)
    },

    getPicturePath(picture){
      return `https://${config.server.host}:${config.server.port}/packages_images/` + picture
    },

    formatDate(dateString) {
        const date = new Date(dateString);
            // Then specify how you want your dates to be formatted
        return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
    },
    
  },

  watch:{
  },

}
</script>


<style scoped>

@import "https://cdn.jsdelivr.net/npm/@voerro/vue-tagsinput@2.7.1/dist/style.css";

p{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;
}

.caption {
  font-size: smaller;
  color: grey;
  padding-top: 0px;
  text-align: left;
  margin-bottom: 0px;
}

</style>
