<template>

  <div v-if="$store.getters.getDataAreLoaded && compoModel" style="display:block; margin-bottom:100px" >

    <div class ="row"  > 
      
      <div class="col-lg-3">
        <b-card style="text-align : left;">
          <b-card-text>

            <b-button @click="$router.push('/Repository')" style="margin-bottom: 20px;">Back to catalog</b-button>
            
            <!--h4 @click="showModelInfo(null, true)" style="cursor: pointer;">Meta</h4-->
            <h4>Meta</h4>
            <p @click="showModelInfo(null, true)" style="padding-left: 20px; cursor: pointer;">
              Metadata
            </p>

            <h4>ModelComposite</h4>
            <p @click="showModelInfo(null, false)" style="padding-left: 20px; cursor: pointer;">
              {{ compoModel.Attributs.name }}
            </p>

            <h4>ModelUnits</h4>
            <p v-for="(v,k) in compoModel.Composition.Model" v-bind:key="k"
            style="padding-left: 20px; margin-bottom: 0; cursor: pointer;"
            @click="showModelInfo(v.Attributs.id, false)">
            {{ v.Attributs.name }}
            </p>
          
          </b-card-text>
        </b-card>
      </div>



      <div id="modelContent"   class="col-lg-7"  >

        <div id="modelId" v-if="errorMsg" class="row">
          <div class="col-md-12">
            {{errorMsg}}
          </div >
        </div >

        <br>

        <div id="ModelTitle">
          <b-card 
              :header="selectedModel.Attributs.id"
              header-bg-variant="secondary"
              header-text-variant="white"
              class="text-left"
          > 
              <b-row no-gutters>
                  <b-col lg="3" >
                      <b-card-img :src="getPicturePath(mainModel.picture)" style="max-width:100px; margin:3px"   alt="Model Preview" ></b-card-img>
                  </b-col>
                  <b-col lg="9" class="text-left">
                      <h4> {{ selectedModel.Attributs.name }}</h4>
                      <p>
                          {{ selectedModel.Description.ShortDescription }}
                      </p>
                  </b-col>
              </b-row>
              <b-row no-gutters >
                <div class="text-center" v-if="!isUnitModel">
                    <b-button variant="primary" @click="downloadModel">Download model</b-button>
                </div >
                <!--div class="col-md-4">
                    <div @mouseleave="showCurrentRating(0)" style="display:inline-block;">
                        <star-rating :show-rating="false" @current-rating="showCurrentRating" @rating-selected="setCurrentSelectedRating" :increment="0.5"></star-rating>
                    </div>
                    <div style="margin-top:10px;font-weight:bold;">{{currentRating}}</div>
                </div -->
                <div class="col-md-3 text-center" v-if="isAdmin() || isEditor()">
                    <b-button variant="primary" @click="$router.push({name: 'Edit', params: { modelid : mainModelId }})">Edit metadata</b-button>
                </div >
                <div class="col-md-3 text-center" v-if="isAdmin()">
                    <b-button variant="primary" @click="$router.push('/Submit')">Upload new version</b-button>
                </div >
                <div class="col-md-3 text-center" v-if="isAdmin()">
                    <b-button variant="danger" v-b-modal.modal-1>Delete model</b-button>
                    <b-modal id="modal-1" title="Delete model ?" ok-title="Yes I'm sure" ok-variant="danger" @ok="deleteModel()">
                      <p class="my-4">{{ `Are you sure you want to delete the version ${this.selectedVersion} of the model ${this.compoModel.Attributs.name} ?`}}</p>
                    </b-modal>
                </div >
                
              </b-row>
          </b-card>
        </div>

        <br>

        <div id="ModelMeta" v-if="this.showMeta">
          <b-tabs content-class="mt-3">
            <b-tab title="Overview">
              <b-table-simple class="text-left" :responsive="true" :striped="true" :hover="true">
                <b-tbody>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Model Identifier</b-td>
                      <b-td> {{ this.compoModel.AddedMetadata.identifiersCompactId }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Submitter</b-td>
                      <b-td> {{ this.compoModel.AddedMetadata.uploaderUsername }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Administrators</b-td>
                      <b-td>
                        <div v-for="admin in this.mainModel.administratorsUsernames" :key="admin">{{ admin }}</div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Authors</b-td>
                      <b-td>
                        <div v-for="author in this.compoModel.Metadata.Authors[0].FirstAndLastName.split(',')" :key="author">{{ author }}</div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Encoder</b-td>
                      <b-td>
                        <div v-for="encoder in this.compoModel.Metadata.Encoders[0].FirstAndLastName.split(',')" :key="encoder">{{ encoder }}</div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Type of model</b-td>
                      <b-td>{{ this.compoModel.Metadata.Features.TypeOfModel.Attributs.type }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Is part of a larger model</b-td>
                      <b-td>{{ this.compoModel.Metadata.Features.IsPartOfLargerModel.Attributs.name }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Algorithm programming language</b-td>
                      <b-td>
                        <div v-for="lang in Object.keys(this.compoModel.Metadata.Transformation.Languages)" :key="lang">{{ lang }}</div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Algorithm source for modeling platform</b-td>
                      <b-td>
                        <div v-for="platform in Object.keys(this.compoModel.Metadata.Transformation.Platforms)" :key="platform">{{ platform }}</div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Validated model using CropMStudio</b-td>
                      <b-td>{{ this.compoModel.Metadata.Features.ValidatedWithCropMStudio.Attributs.YesOrNo }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>License</b-td>
                      <b-td>{{ this.compoModel.Metadata.Attributs.license }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Source repository</b-td>
                      <b-td><a target='_blank' :href="this.compoModel.Metadata.Features.SourceRepository.Attributs.src" >{{ this.compoModel.Metadata.Features.SourceRepository.Attributs.src }}</a></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-tab>
            <b-tab title="History">
              <b-table-simple class="text-left" :responsive="true" :striped="true" :hover="true">
                <b-tbody>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Submitted on</b-td>
                      <b-td> {{ formatDate(this.mainModel.createdAt) }} </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Last modified on</b-td>
                      <b-td> {{ formatDate(this.mainModel.updatedAt) }} </b-td>
                  </b-tr>
                  <b-tr v-for="(v,k) in mainModel.versions" v-bind:key="k">
                      <b-td style='font-weight:bold;'>Version {{ v.Metadata.Attributs.version }} changelog</b-td>
                      <b-td>Submitted by {{ v.AddedMetadata.uploaderUsername }} on {{ formatDate(v.createdAt) }} <br/> {{ v.AddedMetadata.comments }} </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-tab>
          </b-tabs>
        </div>

        <div id="ModelDetails" v-if="!this.showMeta">
          <b-tabs content-class="mt-3">
            
            <b-tab :title="this.isUnitModel ? 'Model' : 'Description'">
              <b-table-simple class="text-left" :responsive="true" :striped="true" :hover="true">
                <b-tbody>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Authors</b-td>
                      <b-td>
                        <div v-for="author in this.selectedModel.Description.Authors.split(',')" :key="author">{{ author }}</div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Reference publications</b-td>
                      <b-td>
                        <div v-for="reference in this.selectedModel.Description.Reference.split(',')" :key="reference"><a target='_blank' :href="reference">{{ reference }}</a></div>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Short description</b-td>
                      <b-td> {{ this.selectedModel.Description.ShortDescription }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Extended description</b-td>
                      <b-td> {{ this.selectedModel.Description.ExtendedDescription }}</b-td>
                  </b-tr>
                  <b-tr>
                      <b-td style='font-weight:bold;'>Time step</b-td>
                      <b-td> {{ this.timestep }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-tab>

            <b-tab title="Inputs" v-if="exists(selectedModel.Inputs) && selectedModel.Inputs.Input.length != 0">
              <div v-if="toArrayIfNeeded(selectedModel.Inputs.Input)">
                <b-table class="text-left" :responsive="true" :striped="true" :hover="true" :items="setInputs(selectedModel.Inputs.Input)"></b-table>
              </div>
            </b-tab>

            <b-tab title="Outputs" v-if="exists(selectedModel.Outputs) && selectedModel.Outputs.Output.length != 0">
              <div v-if="toArrayIfNeeded(selectedModel.Outputs.Output)">
                <b-table class="text-left" :responsive="true" :striped="true" :hover="true" :items="setOutputs(selectedModel.Outputs.Output)"></b-table>
              </div>
            </b-tab>

            <b-tab title="Parameters" v-if="exists(selectedModel.Inputs) && selectedModel.Inputs.Input.length != 0">
              <div v-if="toArrayIfNeeded(selectedModel.Inputs.Input)">
                <b-table class="text-left" :responsive="true" :striped="true" :hover="true" :items="setParameters(selectedModel.Inputs.Input)"></b-table>
              </div>
            </b-tab>

            <b-tab title="Model view" v-if="exists(selectedModel.AddedMetadata) && exists(selectedModel.AddedMetadata.pictures)">
              <b-card v-for="picture in selectedModel.AddedMetadata.pictures" :key="picture">
                <b-card-img :src="getDocPicturePath(picture)"/>
              </b-card>
            </b-tab>
          </b-tabs>
        </div>
          
      </div>


      <div id="modelInfo" class="col-lg-2" >
        
        <div>
          <b-card sub-title="Submitter" >
            <b-card-img 
              src="images/user_icon.png" 
              style="max-width:50px" 
              alt="User"
              top>
            </b-card-img>
            <b-card-text class="modelInfoCardText">
              {{compoModel.AddedMetadata.uploaderUsername}}
            </b-card-text>
          </b-card>
        </div>

        <div>
          <b-card sub-title="Version" >
            <b-card-img 
                src="images/version_iconfinder_128px.png" 
                style="max-width: 50px" 
                alt="Users"
                top>
            </b-card-img>
            <b-card-text class="modelInfoCardText">
              <b-form-select v-model="selectedVersion" :options="this.mainModel.versionsList">
              </b-form-select>
            </b-card-text>
          </b-card>
        </div>

        <div>
          <b-card sub-title="Community" >
            <!--b-card-img 
                src="images/community_iconfinder_128px.png" 
                style="max-width: 50px" 
                alt="Users"
                top>
            </b-card-img-->
            <b-card-text class="modelInfoCardText">
                <div v-for="community in mainModel.linkedCommunities" :key="community">{{ community }}</div>
            </b-card-text>
          </b-card>
        </div>

        <div>
          <b-card sub-title="Keywords" >
            <b-card-img 
                src="images/tag2_inconfinder_128px.png" 
                style="max-width: 50px" 
                alt="Users"
                top>
            </b-card-img>
            <b-form-tags class="modelInfoCardText text-capitalize" input-id="tags-basic" 
              v-model="compoModel.AddedMetadata.keywords"
              disabled placeholder="">
            </b-form-tags>
          </b-card>
        </div>

        <!--div>
          <b-card sub-title="Favourite by" >
          <b-card-img 
                src="images/favourite_iconfinder_128px.png" 
                style="max-width: 50px" 
                alt="Users"
                top>
            </b-card-img>
          </b-card>          
        </div-->
        
      </div>

    </div>
  </div>
  
</template>
<script>

// import StarRating from 'vue-star-rating'
import FileServices from "../services/FileServices"
import config from '../config'


export default {
  name: 'ModelDetails',

  components: {
    // StarRating,
  },

  data() {
    return {
      errorMsg : "",
      
      currentRating: "No Rating",
      currentSelectedRating: "No Current Rating",
      
      mainModelId: null,
      mainModel:null,
      compoModel:null,
      unitModel:null,
      isUnitModel:false,
      selectedModel:{},
      selectedVersion: null,
      showMeta: true,
    }
  },

  async created() {
  },

  async mounted() {
    if (!this.$store.getters.getDataAreLoaded) {
      await this.$store.dispatch('initModels');
    }

    if (this.$route.params.modelid != null){
      this.mainModelId = this.$route.params.modelid;
      this.selectModelById(null)
    }
  },

  computed:{
    timestep () {
      if (this.selectedModel.Attributs.timestep === "1")
        return "Daily"
      else
        return "Hourly"
    }
  },

  methods: {

    isAdmin(){
      return (!this.isUnitModel &&
      this.$store.getters.getUser != null &&
      this.mainModel.administratorsUsernames.includes(this.$store.getters.getUser.userName))
      //this.$store.getters.getUser.associatedModels.findIndex(m => (m.modelId == this.mainModel.id && m.role == "administrator")) != -1)
    },

    isEditor(){
      return (!this.isUnitModel &&
      this.$store.getters.getUser != null &&
      this.mainModel.editorsUsernames.includes(this.$store.getters.getUser.userName))
      //this.$store.getters.getUser.associatedModels.findIndex(m => (m.modelId == this.mainModel.id && m.role == "editor")) != -1)
    },

    getDocPicturePath(picture){
      return `https://${config.server.host}:${config.server.port}/packages/` + this.selectedModel.AddedMetadata.packageName + '/doc/images/' + picture
    },

    getPicturePath(picture){
      return `https://${config.server.host}:${config.server.port}/packages_images/` + picture
    },

    exists(field){
      return !(typeof field === 'undefined')
    },

    formatDate(dateString) {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
    },

    toArrayIfNeeded(obj){
      if(obj instanceof Array ){
        return obj
      }else{ 
        return [obj]
      }
    },

    //currentKey: the key you want to move
    //afterKey: position to move-after the currentKey, null or '' if it must be in position [0]
    //obj: object
    moveObjectElement(currentKey, afterKey, obj) {
      var result = {};
      var val = obj[currentKey];
      delete obj[currentKey];
      var next = -1;
      var i = 0;
      if(typeof afterKey == 'undefined' || afterKey == null) afterKey = '';
      Object.entries(obj).forEach(field => {
          const [k, v] = field;
          if((afterKey == '' && i == 0) || next == 1) {
              result[currentKey] = val; 
              next = 0;
          }
          if(k == afterKey) { next = 1; }
          result[k] = v;
          ++i;
      });
      if(next == 1) {
          result[currentKey] = val;
      }
      if(next !== -1) return result; else return obj;
    },

    /*preferredOrder(obj, order) {
      var newObject = {};
      for(var i = 0; i < order.length; i++) {
          // eslint-disable-next-line no-prototype-builtins
          if(obj.hasOwnProperty(order[i])) {
              newObject[order[i]] = obj[order[i]];
          }
      }
      return newObject;
    },*/

    setInputs(input_or_output){
      let array_of_obj = this.toArrayIfNeeded(input_or_output);
      let items = [];
      for(let obj of array_of_obj){
        if(obj.Attributs.inputtype == "variable"){
          let objCopy = JSON.parse(JSON.stringify(obj))
          delete objCopy.Attributs["inputtype"]
          objCopy.Attributs["category"] = objCopy.Attributs["variablecategory"]
          delete objCopy.Attributs["variablecategory"]
          objCopy.Attributs["type"] = objCopy.Attributs["datatype"]
          delete objCopy.Attributs["datatype"]
          objCopy = this.moveObjectElement("type", "name", objCopy.Attributs)
          objCopy = this.moveObjectElement("category", "type", objCopy)
          items.push(objCopy)
        }
      }
      return items;
    },

    setParameters(input_or_output){
      let array_of_obj = this.toArrayIfNeeded(input_or_output);
      let items = [];
      for(let obj of array_of_obj){
        if(obj.Attributs.inputtype == "parameter"){
          let objCopy = JSON.parse(JSON.stringify(obj))
          delete objCopy.Attributs["inputtype"]
          objCopy.Attributs["category"] = objCopy.Attributs["parametercategory"]
          delete objCopy.Attributs["parametercategory"]
          objCopy.Attributs["type"] = objCopy.Attributs["datatype"]
          delete objCopy.Attributs["datatype"]
          objCopy = this.moveObjectElement("type", "name", objCopy.Attributs)
          objCopy = this.moveObjectElement("category", "type", objCopy)
          items.push(objCopy)
        }
      }
      return items;
    },

    setOutputs(input_or_output){
      let array_of_obj = this.toArrayIfNeeded(input_or_output);
      let items = [];
      for(let obj of array_of_obj){
        let objCopy = JSON.parse(JSON.stringify(obj))
        objCopy.Attributs["category"] = objCopy.Attributs["variablecategory"]
        delete objCopy.Attributs["variablecategory"]
        objCopy.Attributs["type"] = objCopy.Attributs["datatype"]
        delete objCopy.Attributs["datatype"]
        objCopy = this.moveObjectElement("type", "name", objCopy.Attributs)
        objCopy = this.moveObjectElement("category", "type", objCopy)
        items.push(objCopy)
      }
      return items;
    },

    async deleteModel(){
      this.errorMsg =""
      await this.$store.dispatch('deleteModel', {modelid: this.mainModel.id, version: this.selectedVersion, user: this.$store.state.user.email});
      this.selectedVersion = null
      this.selectModelById()
      if (typeof this.mainModel === 'undefined'){
        this.$router.go(-1)
      }
    },

    async downloadModel(){
      const res =  await FileServices.downloadZip(this.selectedModel.AddedMetadata.zipName)
      console.log(res)
    },

    selectModelById(unitModelId){
      [this.isUnitModel, this.mainModel, this.compoModel, this.unitModel, this.selectedVersion] = this.$store.getters.getModelByIdAndVersion(this.mainModelId, this.selectedVersion, unitModelId)
      this.selectedModel = this.isUnitModel ? this.unitModel : this.compoModel
    },

    showModelInfo(unitModelId, showMeta){
      this.showMeta = showMeta
      this.selectModelById(unitModelId)
    },

    showCurrentRating(rating) {
      this.currentRating = (rating === 0) ? this.currentSelectedRating : "Click to select " + rating + " stars"
    },
    
    setCurrentSelectedRating(rating) {
      this.currentSelectedRating = "You have Selected: " + rating + " stars";
    }
    
  },

  watch:{
    selectedVersion: function(){
      this.selectModelById()
    }
  },

}
</script>

<style>
.tree-node-children .tree-node-label span {
  font-size: small;
}
</style>

<style scoped>

p{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 2px;
}

.modelInfoCardText{
  font-size: 0.75em;
}

</style>
