<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          Version control
        </h1>

        <p style="text-align: justify;">
          We use <a
            target="_blank"
            href="https://github.com/"
            class="text-decoration-none">GitHub</a> as a software version control system. Most documents are available
          on <a
            target="_blank"
            href="https://readthedocs.org/"
            class="text-decoration-none">Read the Doc</a> and are linked to the corresponding GitHub repository.
        </p>
        <p style="text-align: justify;">
          The source and documentations of the Crop2ML tools are available on the public
          <a
            target="_blank"
            href="https://github.com/AgriculturalModelExchangeInitiative"
            class="text-decoration-none">AgriculturalModelExchangeInitiative</a> organization on GitHub.
        </p>
        <p style="text-align: justify;">
          We invite users to deposit their Crop2ML models on the public <a
            target="_blank"
            href="https://github.com/Crop2ML-Catalog"
            class="text-decoration-none">Crop2ML-Catalog</a> organization on GitHub.

        </p>
      </b-col>
    </b-row>
  </div>

</template>
<script>


export default {
  name: 'About_VersionControl',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
