<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          Term of use
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="text-left mb-5">
          To the extent possible under law, all copyright and related or neighboring rights to the entire model
          repository and associated information and data distributed by CropMRepository have been dedicated to the
          public domain worldwide.
        </p>

        <p class="text-left mb-5">
          The CropMRepository dataset, which includes the encoded models and their annotations, is provided under the
          terms of open licenses. This means you are entitled to use the encoded models in absolutely any manner you
          deem suitable, verbatim, or with modification, alone or embedded in a larger context, redistribute them
          (commercially or not), in a restricted way or not.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4 class="heading text-left">
          What are the conditions of use and distribution for unmodified models originating from CropMRepository
          database?
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="text-left mb-5">
          You can use and freely distribute the models present in CropMRepository in their current form.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4 class="heading text-left">
          What are the conditions of use and distribution for modified models that originated from CropMRepository?
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="text-left mb-5">
          You can modify and freely distribute a modified version of a model that is present in CropMRepository in whole
          or part. A modified model is defined as one where an existing model is extended/reduced/merged with other
          models. In this case, there is a change in the biological description and components.
        </p>

        <p class="text-left mb-5">
          The modified model must be renamed, and a new submission to CropMRepository should be made.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h4 class="heading text-left">
          Disclaimer
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="text-left mb-5">
          The models deposited in CropMRepository are provided "as is", without warranty of any kind, express or
          implied, including but not limited to the warranties of merchantability, fitness for a particular purpose and
          noninfringement. In no event shall the AMEI partners be liable for any claim, damages or other liability
          whatsoever, whether in an action of contract, tort or otherwise, arising from, out of or in connection with
          the models or the use or other dealings in the repository.
        </p>
      </b-col>
    </b-row>
  </div>


</template>
<script>


export default {
  name: 'About_TermsOfUse',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
