<template>
  
  <b-navbar class="topnav" toggleable="lg" type="dark" variant="dark" >
    <b-navbar-brand href="#/Home">CropMRepository</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="#/Home">Home</b-nav-item>
        <b-nav-item href="#/Repository">Catalog</b-nav-item>
        <b-nav-item href="#/Communities">Communities</b-nav-item>
        <b-nav-item href="#/Submit">Submit</b-nav-item>
        <b-nav-item-dropdown text="Crop2ML" >
          <b-dropdown-item href="#/Crop2ML/WhatIsCrop2ML">What is Crop2ML ?</b-dropdown-item>
          <b-dropdown-item href="#/Crop2ML/WhatIsCyML">What is CyML ?</b-dropdown-item>
          <b-dropdown-item href="#/Crop2ML/Tools">Crop2ML tools</b-dropdown-item>
          <b-dropdown-item href="#/Crop2ML/Platforms">Supported modeling platforms</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="AMEI" >
          <b-dropdown-item href="#/AMEI/Vision">Vision and motivations</b-dropdown-item>
          <b-dropdown-item href="#/AMEI/Supports">Supports</b-dropdown-item>
          <b-dropdown-item href="#/AMEI/Members">Core members</b-dropdown-item>
          <b-dropdown-item href="#/AMEI/Events">Events</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="About" >
          <b-dropdown-item href="#/About/FAQ">Submission FAQs</b-dropdown-item>
          <b-dropdown-item href="#/About/VersionControl">Version control</b-dropdown-item>
          <b-dropdown-item href="#/About/Licenses">Licenses</b-dropdown-item>
          <b-dropdown-item href="#/About/HowToCite">How to cite</b-dropdown-item>
          <b-dropdown-item href="#/About/TermsOfUse">Terms of use</b-dropdown-item>
          <b-dropdown-item href="#/About/ContactUs">Contact us</b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em v-if="$store.state.user === null">User</em>
            <em v-else>{{` ${$store.state.user.userName}`}}</em>
          </template>

          <b-dropdown-item href="#/Register">Register</b-dropdown-item>
          <b-dropdown-item href="#/SignIn">Sign in</b-dropdown-item>
          <b-dropdown-item href="#/Profile">Edit profile</b-dropdown-item>
          <b-dropdown-item href="#/SignOut">Sign out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>

  </b-navbar>

</template>

<script>

export default {
  name: "NavBar",
  components: {},
  
  async created () {
    await this.$store.dispatch('getUserDetails');
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
