<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          Supports
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-list-group flush>
          <b-list-group-item>
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="images/logoDigitAg-reserve.png" class="avatar" width="180px"/>
                <br>
                <b-img alt="Image placeholder" src="images/anr_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <p class="text-sm-left mb-0">Funding for the development of Crop2ML and the crop2ml.org application
                  comes from the Digital Agriculture Convergence Laboratory (#DigitAg) through funding by the French
                  National Research Agency (ANR) under the Investments for the Future Program (grant no.
                  ANR-16-CONV-0004).</p>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item>
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="images/inrae_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <p class="text-sm-left mb-0">Additional funding comes from the INRAE Division of AgroEcosystem and the
                  Modeling of Field Crop Functioning (MFPC) scientific network.
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item href="https://www.inrae.fr/en">
            <b-row align-v="center">
              <p>The following universities and research institutions provide in-kind support to collaborative research
                and
                offer partnership via the participation of representatives.</p>
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="images/inrae_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <p class="text-sm-left mb-0">French National Research Institute for Agriculture, Food and the
                  Environment, France
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item href="https://www.cirad.fr/en">
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="images/Logo_cirad.jpeg" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <p class="text-sm-left mb-0">French agricultural research and international cooperation organization,
                  France
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item href="https://www.inres.uni-bonn.de/en">
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="images/bonn_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <p class="text-sm-left mb-0">The University of Bonn Institute of Crop Science and Resource Conservation
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item href="https://www.crea.gov.it/en/home">
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="images/crea_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <p class="text-sm-left mb-0">
                  Council for Agricultural Research and Economics, Italy
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item href="https://ec.europa.eu/jrc/en">
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="images/jrc_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <p class="text-sm-left mb-0">
                  Joint Research Centre, European Commission
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item href="https://ifas.ufl.edu/">
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="images/uf_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <p class="text-sm-left mb-0">
                  The University of Florida's Institute of Food and Agricultural Sciences, USA
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item href="https://www.wur.nl/en.htm">
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="images/wageningen_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <p class="text-sm-left mb-0">
                  Wageningen University & Research, The Netherlands
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item href="https://www.csiro.au/">
            <b-row align-v="center">
              <b-col md="auto">
                <!-- Avatar -->
                <b-img alt="Image placeholder" src="images/csiro_logo.png" class="avatar" width="180px"/>
              </b-col>
              <b-col class="ml--2">
                <p class="text-sm-left mb-0">
                  Commonwealth Scientific and Industrial Research Organisation, Australia
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>

</template>
<script>


export default {
  name: 'AMEI_Supports',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
