<template>
  <div id ="Community" 
    style="width:100%; margin:20px;" 
  >
    
      <center>
        <h3 v-if="createMode || editMode">{{` Create or edit a community`}}</h3>
        <h3 v-if="readerMode">{{` View community details`}}</h3>
        <b-card
          
          style="max-width: 40rem; margin-top:3em;"
          class="mb-2"
        >

          <b-card-img 
            id = "displayedImg"
            :src="getPicturePath(community.picture)" 
            style="max-width: 150px" 
            alt="Community"
            top>
          </b-card-img>

          <div v-if="$store.getters.getUser !== null || readerMode">

            <h3>{{community.name}}</h3>

            <b-input-group prepend="Name">
              <b-form-input
                v-model="community.name"
                type="text"
                :disabled="editMode || readerMode"
              >
              </b-form-input>
            </b-input-group>


            <b-input-group prepend="Description" style="margin-top:1em">
              <b-form-textarea
                
                v-model="community.description"
                type="text"
                :disabled="readerMode"
              >
              </b-form-textarea>
            </b-input-group>

            <br>

            <b-form-file v-if="createMode || editMode"
              id="inputImgFileForm"
              v-model="inputImgFile"
              :state="Boolean(inputImgFile)"
              placeholder="Select or drop img (.jpg, .png, .gif) here"
              drop-placeholder="Drop an image (.jpg, .png, .gif) here ..."
              accept=".jpg, .png, .gif"
              @change="previewInputImgFile()"
              >
            </b-form-file>

            <!-- Prop `add-on-change` is needed to enable adding tags vie the `change` event -->
            <!-- The <template #first> is required to prevent bugs with Safari -->
            <b-input-group prepend="Model packages" style="padding-top: 1em; text-align:left;">
              <b-form-tags
                id="tags-component-select"
                v-model="community.modelPackages"
                add-on-change
                no-outer-focus
                :disabled="readerMode"
              >
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                  <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        @remove="removeTag(tag)"
                        :title="tag"
                        :disabled="disabled"
                      >{{ tag }}</b-form-tag>
                    </li>
                  </ul>
                  <b-form-select
                    v-bind="inputAttrs"
                    v-on="inputHandlers"
                    :disabled="disabled || availablePackageOptions.length === 0"
                    :options="availablePackageOptions"
                  >
                    <template #first>
                      <option disabled value="">Choose a model...</option>
                    </template>
                  </b-form-select>
                </template>
              </b-form-tags>
            </b-input-group>
            

            <b-input-group v-if="createMode || editMode" prepend="Administrators" style="padding-top: 1em; text-align:left;">
              <b-form-tags class="form-control"  style="background: white;"
                  v-model="community.administrators"
                  separator=","
                  placeholder= "Enter e-mail separated by comma"
                  invalid-tag-text="Please enter a valid email address"
                  :tag-validator="emailValidator"
                  duplicate-tag-text="duplicated e-mail"
                ></b-form-tags>
            </b-input-group>

            <b-input-group v-if="readerMode" prepend="Administrators" style="padding-top: 1em; text-align:left;">
              <b-form-tags class="form-control"  style="background: white;"
                  v-model="community.administratorsUsernames"
                  separator=","
                  placeholder= ""
                  :tag-validator="emailValidator"
                  disabled
                ></b-form-tags>
            </b-input-group>

            <b-input-group prepend="Created by" style="margin-top:1em">
              <b-form-input
                v-model="community.createdByUsername"
                type="text"
                disabled
              >
              </b-form-input>
            </b-input-group>

            <b-button v-if="createMode || editMode" variant="primary" @click="submitCommunity()" style="margin-top:1em">
              Submit
            </b-button>
            <div>
              <b-button @click="$router.go(-1)" style="margin-top:1em">
                Cancel
              </b-button>
            </div>



          </div>
          <div v-else>
              Please <a href="#/SignIn">sign-in</a>  or <a href="#/register"> register </a> before creating a community
          </div>

        </b-card>
      </center>
  
</div>

</template>
<script>


import CommunityServices from "../../services/CommunityServices"
import config from '../../config'
const path = require('path');

export default {

  data() {
    return {
      //name: null,
      //description: "",
      regEmail: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      community: {},
      inputImgFile: null,
      submitted:false,
      packagesOptions:[],
      createMode: false,
      editMode: false,
      readerMode: false
    };
  },

  async mounted() {
    if (!this.$store.getters.getDataAreLoaded) {
      await this.$store.dispatch('initModels');
    }
    if (this.$route.name == "NewCommunity"){
      this.createMode = true
    }
    else if (this.$route.name == "EditCommunity"){
      this.editMode = true
    }
    else if (this.$route.name == "ViewCommunityDetails"){
      this.readerMode = true
    }

    if (this.$route.params.community != null){
      this.community = this.$route.params.community
    } else {
      this.community = {
        name: "",
        description: "",
        picture: "community_iconfinder_128px.png",
        administrators: [],
        modelPackages: [],
      }
    }

    this.packagesOptions = this.$store.getters.getAlphabeticListOfModels
  },

  computed: {
    availablePackageOptions() {
      let values = this.community.modelPackages || []
      return this.packagesOptions.filter(opt => values.indexOf(opt) === -1)
    },
  },

  methods: {
    previewInputImgFile() {
      var file = document.getElementById('inputImgFileForm').files[0];
      var reader  = new FileReader();
      reader.onload = function(e)  {
          var image = document.getElementById("displayedImg");
          image.src = e.target.result;
        }
      reader.readAsDataURL(file);
     },

    getPicturePath(picture){
      return `https://${config.server.host}:${config.server.port}/community_images/` + picture
    },
    
    async submitCommunity(){
      let image = this.inputImgFile;
      if (image != null){
        var extension = "." + image.name.split('.').pop();
        var name = path.basename(image.name, extension)
        this.community.picture = name + Date.now() + extension
      }

      if (this.community.createdBy == null){
        this.community.createdBy = this.$store.getters.getUser.email,
        this.community.administrators.push(this.$store.getters.getUser.email)
      }

      await CommunityServices.createCommunity(image,this.community)

      this.submitted =true;
      this.$router.push("/Communities")
    },

    emailValidator(email){
      return (email == "")? "" : (this.regEmail.test(email)) ? true : false;
    },

  },

  watch:{
  }

};
</script>

<style scoped>
.fake{
  text-align: left;
}

</style>
