<template>
  <div id="app" >

    <header id="header">
      <div id="navbar"  >
        <Navbar />
      </div>
    </header>
    
    <main id="main">
      <router-view />
    </main>

    <footer > 
      <Footer />
    </footer>
    
  </div>
</template>

<script>



import Navbar from "./components/Navbar";
import Footer from "./components/Footer";


export default {
  name: 'App',
  components: {    
    Navbar,
    Footer,
  }
}

/*window.onunload = function() {
  sessionStorage.removeItem("user");
  return undefined;
};*/
</script>

<style scoped>
 #app { 
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  overflow-x: clip;
 }

 #main{
   width: 100%;
   margin-bottom: 100px;
 }
 

 



</style>
