<template>
  
  <div class="footer">
    <footer class="bg-dark">
      <div class="icones">
        <a  href="#/LegalMentions" target="_blank">Legal notices</a> | <a  href="#/PrivacyNotice" target="_blank">Privacy Notice</a>
      </div>
    </footer>
  </div>
  
    
</template>

<script>
  export default {
    name: 'Footer',
    components: {
    }
  }
</script>

<style scoped>

.footer{
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  /* overflow: none; */

}


img {
  border-radius: 0%;
}

.icones{background: lightgray;padding: 7px; width: 100%; }
.icones p {text-align:center;}
.icones img {max-width: 5em; height: 2em; }
.icones img:hover {max-width: 6em;height: 2.4em;}


</style>