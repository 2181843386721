<template>
  
  <div> 

      <h1 style="margin: 30px;">
        Welcome to the crop modeling community model repository
      </h1>
      <p style="text-align: justify; margin: 50px;">
        CropMRepository provides an accessible online location for storing and efficiently retrieving and sharing simulation models of soil and crop growth biophysical processes. Models are stored in the open-source format <a href="#/Crop2ML/WhatIsCrop2ML">Crop Modeling MetaLanguage (Crop2ML)</a> and supported by the <a href="#/AMEI/Vision"> Agricultural Model Exchange Initiative (AMEI). </a> 
        <br><br>
        CropMRepository and Crop2ML follow the Minimum Information Required in the Annotation of Models (<a href="https://co.mbine.org/standards/miriam">MIRIAM</a>) and the Open Modeling Foundation (<a href="https://openmodelingfoundation.github.io/">OMF</a>) sets of guidelines that define how a model should be encoded, annotated, and documented in order to be successfully distributed, exchanged and ultimately reused.
        <br><br>
        All models are provided under an open-source license. This means that the models are available freely for use, modification, and distribution, to all users.
        <br><br>
        The models deposited in CropMRepository are <a href="#/About/HowToCite">citable</a> by using the unique and perennial model identifier assigned to each model.
        <br><br>
        Browse or search through the <a href="#/Repository">catalog</a> of models below, download models or <a href="#/Submit">submit</a> your own models.
      </p>
      <p>
        CropMRepository is registered with <a href="https://fairsharing.org/3806" target='_blank'>FAIRsharing.org</a> and <a href="https://registry.identifiers.org/registry/crop2ml" target='_blank'>Identifiers.org</a>
      </p>
      <div class="row" style="justify-content: center;"> 
        <div class="col-lg-2">
          <a href="https://fairsharing.org/3806" target="_blank" class="col-sm-4 btn-floating btn-sm btn-fb mx-1 "><i><img height="50px" src="images/fairsharing.png" ></i></a>
        </div>
        <div class="col-lg-2">
          <a href= "https://registry.identifiers.org/registry/crop2ml" target="_blank" class="col-sm-4 btn-floating btn-sm btn-fb mx-1 "><i><img height="50px" style="alignleft: 0" src="images/identifiers.jpg" ></i></a>
        </div>
      </div>

    
  </div>
  
   
</template>
<script>



export default {
  name: 'Home',

   data() {
      return {
        
      }
    },

  components: {
    

  },

  async created() {
    
  },

  async mounted() {
    
  }

}
</script>

<style scoped>

</style>
