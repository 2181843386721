import Vue from "vue";
import Vuex from "vuex";

import ModelServices from '../services/ModelServices.js'
//import AuthServices from '../services/AuthServices.js'
import VueJwtDecode from "vue-jwt-decode";

Vue.use(Vuex);


export default new Vuex.Store({

   state: {
     models : new Map(),
     dataAreloaded:false,
     currentNavPath: null,
     user: null,
     keywordsObj: {},
     catalogFilters: [],
     token: null,
   },


   getters: { // computed methods

      getModels: (state) => {
         return state.models;
      },

      getModelById: (state) => (id) => {
         return state.models.get(id);
      },

      getModelByIdAndVersion: (state) => (mainModelId, version, unitModelId) => {
         let isUnitModel = true
         let mainModel = state.models.get(mainModelId)
         let compoModel = null
         let unitModel = null
         if (typeof mainModel !== 'undefined'){
            if (version == null) {
               version = mainModel.versionsList.sort()[mainModel.versionsList.length -1]
            }
            compoModel = mainModel.versions.find(m => m.Metadata.Attributs.version == version)
            if (unitModelId == null){
               isUnitModel = false
            } else {
               unitModel = compoModel.Composition.Model.find(m => m.Attributs.id == unitModelId).ModelContent
            }
         }
 
         return [isUnitModel, mainModel, compoModel, unitModel, version]
      },

      getModelIds: (state) => {
         return Array.from(state.models.keys());
      },

      getDataAreLoaded:(state) =>{
         return state.dataAreloaded
      },

      getLoggedUserEMail:(state) =>{
         if (state.user == null)
            return null
         else
            return state.user.email
      },
      
      getUser:(state) =>{
         return state.user
      },

      getToken:() =>{
         return sessionStorage.getItem("user")
         //return state.token
      },

      /*isLoggedIn(state) {
         return !!state.token;
       },*/

      getKeywords:(state) =>{
         let allWords = Array.prototype.concat([],Object.keys(state.keywordsObj))
         allWords.sort()
         let allWordsObjects = []
         for (const w of allWords) {
            allWordsObjects.push({"value":w})
         }
         return allWordsObjects
      },

      getCatalogFilters:(state) =>{
         return state.catalogFilters
      },

      getAlphabeticListOfModels:(state)=>{
         let modelIds = []
         for(let m of state.models.keys()){
            modelIds.push(m)
         }
         
         return modelIds.sort()
      },

      getListOfPersonalModels:(state)=>{
         let models = []
         if (typeof state.user.associatedModels === "undefined")
            return models
            
         for(let m of state.models.keys()){
            let index = state.user.associatedModels.findIndex(mod => mod.modelId == m)
            if (index != -1)
               models.push(state.user.associatedModels[index])
         }
         
         return models.sort((a,b) => (a.modelId > b.modelId) ? 1 : ((b.modelId > a.modelId) ? -1 : 0))
      },
   },

   mutations: {

      setModels: (state, models) =>{
         state.models = models;
      },

      addModel: (state, model) => {
         state.models.set(model.id,model)

         for(let k of model.versions[0].AddedMetadata.keywords){
            if(!(Object.prototype.hasOwnProperty.call(state.keywordsObj,k))){
               state.keywordsObj[k]=[]
            }
            state.keywordsObj[k].push(model.id)
         }
      },

      deleteModel: (state, modelid) => {
         state.models.delete(modelid)
      },

      setModel: (state, model) => {
         state.models.set(model.id,model)
      },

      setDataAreLoaded: (state, bool) =>{
         state.dataAreloaded =bool
      },
      
      setCurrentNavPath: (state,val) =>{
         state.currentNavPath=val;
      },

      setUser:(state,val) =>{
         state.user = val
      },

      setToken(state, token) {
         //state.token = token;
         sessionStorage.setItem("user", token);
         this.dispatch('getUserDetails');
      },

      setCatalogFilters: (state, tags) => {
         state.catalogFilters = tags
      }
   },

   actions: {

      async initModels({ state, commit }) {

         return new Promise((resolve, reject) => {
            try { 
               ModelServices.getAllModels().then(models =>{ 
                  for(const model of models){
                     commit('addModel', model)
                  }
                  commit('setDataAreLoaded',true)
                  resolve(state.models)
               })
            } catch (err) { 
               console.error(err);
               reject(err);
            }
         })
      },

      async saveModel({commit}, data){
         const res = await ModelServices.saveModel(data.model, this.getters.getLoggedUserEMail, data.isUploaded, data.image)
         commit("setModel", res.model);
         return res.success;
      },

      async deleteModel({commit}, modelData){
         const res = await ModelServices.deleteModelById(modelData.modelid, modelData.version, modelData.user)
         let success = res.success
         let updatedModel = res.model
         if(success){
            if (updatedModel == "" ){
               commit('deleteModel',modelData.modelid)
            } else {
               commit('setModel',updatedModel)
            }
         } 
         return success;
      },

      getUserDetails({ commit }) {
         // get token from sessionStorage
         let token = sessionStorage.getItem("user");
         if (token != null) {
            try {
               //decode token here and attach to the user object
               let decoded = VueJwtDecode.decode(token);
               commit('setUser', decoded)
               //this.user = decoded;
               } catch (error) {
                 // return error in production env
                 console.log(error, 'error from decoding token')
               }
         }
       },
   }
});

