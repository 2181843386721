<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          Submissions FAQs
        </h1>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Who can submit a new model?
        </h3>
        <p style="text-align: justify;">
          Anyone can submit a model to CropMRepository by creating an account on crop2ml.org. Even if you are not the
          author or encoder of a model you can submit it for inclusion in CropMRepository. If you do so, you will be
          recorded as the submitter, not as an author or an encoder. However, we do encourage you to contact the
          encoders of the model to be sure they are comfortable with the submission.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Who can submit a revision for a model that is already on CropMRepository?
        </h3>
        <p style="text-align: justify;">
          Only the administrator of a model can submit a revision. If you wish to contribute to the revision to a model
          already included in CropMRepository, we invite you to contact the administrator of that model.

        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Which models can be submitted to CropMRepository?
        </h3>
        <p style="text-align: justify;">
          Any simulation model describing a soil or crop growth biophysical process can be submitted to CropMRepository
          if it has been <b>published in a peer-reviewed scientific journal</b> or posted in a preprint server (such as
          BioRxiv) prior to, or concurrently with, submission to a journal.
        </p>
        <p style="text-align: justify;">
          Authors are encouraged to submit their models before publication of the associated paper. That way they can
          receive a unique and perennial model identifier that they can use in the publication as a reference, but the
          model will only be publicly available on CropMRepository once the paper is published, unless the authors would
          like to make their model available to the scientific community ahead of its publication.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          How can I submit a new model?
        </h3>
        <p style="text-align: justify;">
          The submission is entirely processed via the submission page. Once the model has been submitted, the submitter
          will receive an email notification with a unique, stable and perennial submission identifier. This identifier
          is composed of the prefix "crop2ml" followed by a semicolon and 10 digits, for example crop2ml:0000000135.
          This identifier can be used to reference the model in publications, and once the model is publicly available
          on CropMRepository, users can directly access the model using this identifier.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          Can I update the details of an existing model?
        </h3>
        <p style="text-align: justify;">
          A model submitted to CropMRepository remains personally private and can only be seen by the submitter and the
          administrators (indicated by the submitter at the time of submission) until they want to publish it on their
          side. Administrators of a model can update the metadata entered during submission and the model files at any
          time before making the model public.
        </p>
        <p style="text-align: justify;">
          CropMRepository public catalog is monotonic, meaning that once a model is published, it cannot be removed from
          the catalog and any changes made to the model files are revision control. The metadata entered at submission
          can still be edited after the model has been made public.
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          What are the different types of contributors to a model in CropMRepository?
        </h3>
        <div class="d-flex w-100 justify-content-between">
          <div>
            <div class="d-flex w-100 align-items-start">
              <h5 class="mb-1">
                We distinguish the following four types of contributors to a model in CropMRepository:
              </h5>
            </div>
          </div>
        </div>
        <b-list-group>
          <b-list-group-item class="flex-column align-items-start">
            <p class="text-left mb-1">
              The <b>Authors</b> of a model are the individuals who initially described the model in a peer-reviewed
              publication.

            </p>

          </b-list-group-item>
          <b-list-group-item class="flex-column align-items-start">

            <p class="text-left mb-1">
              The <b>Encoders</b> of a model are the individuals who actually encoded the model in its present form,
              based on
              the published article. Encoders may also be authors, the submitter, and/or administrators of the model.
            </p>

          </b-list-group-item>
          <b-list-group-item class="flex-column align-items-start">
            <p class="text-left mb-1">
              The <b>Submitter</b> of a model is the individual who deposited the model in the CropMRepository catalog.
            </p>
          </b-list-group-item>
          <b-list-group-item class="flex-column align-items-start">
            <p class="text-left mb-1">
              The <b>Administrators</b> of a model on CropMRepository can edit the metadata of the model entered at
              submission,
              submit a new version (revision) of the model, or delete the (version) model from CropMRepository. By
              default, the submitter has the rights of an administrator, but you can also give these rights to other
              individuals.
            </p>
          </b-list-group-item>
          <p style="text-align: justify;">
            The authors and encoders are defined in the Crop2ML model specification, while the submitter and the
            administrators are defined during submission to CropMRepository.
          </p>
        </b-list-group>
      </b-col>
    </b-row>
  </div>

</template>
<script>


export default {
  name: 'About_FAQ',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
