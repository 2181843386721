<template>
  <div class="container">

    <b-row>
      <b-col>
        <h1 class="heading">
          Contact us
        </h1>

        <p style="text-align: justify;">
          We organize and engage the community through <a href="#/AMEI/Events">workshops, trainings</a>, and
          crop2ml.org. </p>
        <p style="text-align: justify;">
          AMEI team members can be contacted through the <a href="#/AMEI/Members">AMEI partners</a>page. </p>
        <p style="text-align: justify;">
          We hope you'll find the information you need here, but if not, please feel free to contact us by filling out
          the form below. </p>
        <p style="text-align: justify;">
          You can also use this form to send us your feedback. Any feedback/comment is welcome!
        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <div>
          <h4>Contact form:</h4>
          <div v-if="isSending" class="loading">Sendig...</div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-group id="input-group-2" label="Your Name:" label-for="input-2">
              <b-form-input
                  id="input-2"
                  v-model="form.name"
                  placeholder="Enter name"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-1"
                label="Email address:"
                label-for="input-1"
                description="We'll never share your email with anyone else."
            >
              <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter email"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Message subject:" label-for="input-3">
              <b-form-input
                  id="input-3"
                  v-model="form.subject"
                  placeholder="Message subject"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="Your message:" label-for="input-3">
              <b-form-textarea
                  id="textarea"
                  v-model="form.message"
                  placeholder="Your message"
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-form>
<!--          <b-card class="mt-3" header="Form Data Result">-->
<!--            <pre class="m-0">{{ form }}</pre>-->
<!--          </b-card>-->
        </div>
      </b-col>
    </b-row>
  </div>

</template>
<script>


import AuthServices from "@/services/AuthServices";

export default {
  name: 'About_Contact',

  data() {
    return {
      form: {
        email: '',
        name: '',
        subject: '',
        message: ''
      },
      show: true,
      isSending: false
    }
  },

  methods: {
    /**
		 * Clear the form
		 */
		clearForm() {
			for (let field in this.form) {
				this.form[field] = ''
			}
		},

    /**
		 * Submit the form
		 */
    async onSubmit(event) {
      event.preventDefault();

      this.isSending = true;

      setTimeout(async () => {
        // Build contact object
        let contact = {
          name: this.form.name,
          email: this.form.email,
          subject: this.form.subject,
          message: this.form.message
        }
        // Send form to server
        const res = await AuthServices.contactUs(contact)
        if(res.errorMsg === undefined){
          console.log(res);
          this.clearForm();
          this.isSending = false;
        }else{
          this.signInErrorMsg = res.errorMsg
        }
      }, 1000);

      // alert(JSON.stringify(this.form))
    },

    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.clearForm()
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
