const axios = require('axios');
import config from '../config'

var url = `https://${config.server.host}:${config.server.port}/server/`;

class CommunityServices{

    static createCommunity = async (file, communityInfo) =>{
        return new Promise((resolve, reject) => {
            try { 
                const formData = new FormData();
                formData.append("picture", file);
                formData.append("body", JSON.stringify(communityInfo));
                axios.post(url+'community/createCommunity', formData, {
                    // headers: formData.getHeaders()
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `${sessionStorage.getItem("user")}`
                    }
                }).then( res =>{
                    resolve(res.data.value)
                })
            } catch (err) { 
                console.error(err);
                reject(err);
            }
        })
    }


    static getAllCommunities = () =>{
        return new Promise((resolve, reject) => {
            try { 
                axios.get(url + "community/getAllCommunities").then(res => {
                    resolve(res.data) ;
                })
            } catch (err) { 
                console.error(err);
                reject(err);
            }
        })
    }
}


export default CommunityServices