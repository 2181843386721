<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          How to cite Crop2ML
        </h1>

        <p style="text-align: justify;">
          Below are references related to Crop2ML and CyML. Please cite these references when referring to or making use
          of the Crop2ML framework and tools:
        </p>
        <p style="text-align: justify;">
          <b>Midingoyi CA, Pradal C, Athanasiadis IN, Donatelli M, Enders A, Fumagalli D, Garcia F, Holzworth D,
            Hoogenboom
            G, Porter C, Raynal H, Thorburn P, Martre P.</b> 2020. Reuse of process-based models: automatic
          transformation
          into many programming languages and simulation platforms. in silico Plants: diaa007.
          <a
              target="_blank"
              href="https://doi.org/10.1093/insilicoplants/diaa007"
              class="text-decoration-none">https://doi.org/10.1093/insilicoplants/diaa007</a>

        </p>
        <p style="text-align: justify;">
          <b>Midingoyi CA, Pradal C, Enders A, Fumagalli D, Raynal H, Donatelli M, Athanasiadis IN, Porter C, Hoogenboom
            G,
            Holzworth D, Garcia F, Thorburn P, Martre P.</b> 2021. Crop2ML: An open-source multi-language modeling
          framework
          for the exchange and reuse of crop model components. Environmental Modelling & Software 142: 105055.
          <a
              target="_blank"
              href="https://doi.org/10.1016/j.envsoft.2021.105055"
              class="text-decoration-none">https://doi.org/10.1016/j.envsoft.2021.105055</a>

        </p>
        <p style="text-align: justify;">

          <b>Midingoyi CA, Pradal C, Enders A, Fumagalli D, Lecharpentier P, Raynal H, Donatelli M, Fanchini D,
          Athanasiadis IN, Porter C, Hoogenboom G, Oliveira FAA, Holzworth D, Martre P.</b> (2023)
          Crop modeling frameworks interoperability through bidirectional source code transformation.
          Environmental Modelling & Software 168: 105790.  <a
              target="_blank"
              href="https://doi.org/10.1016/j.envsoft.2023.105790"
              class="text-decoration-none">https://doi.org/10.1016/j.envsoft.2023.105790</a>.

        </p>
      </b-col>
    </b-row>
    <b-row class="text-left" style="margin-top: 20px">
      <b-col>
        <h3>
          How to cite a model present in CropMRepository?
        </h3>
        <p style="text-align: justify;">
          The best way to cite a model present in CropMRepository is to state the reference publication associated with
          the model and to mention the model's identifier. The model identifier is a unique and perennial identifier
          assigned to any model deposited in CropMRepository. It is of the form “crop2ml:” followed by 10 digits.
        </p>
        <p style="text-align: justify;">
          You can also cite a CropMRepository entry as follows:
        </p>
        <p style="text-align: justify;">
          authors, year, model name, version, Crop Model Repository, record model identifier.
        </p>
        <p style="text-align: justify;">
          For example: <br>
          Midingoyi CA, Pradal C, Martre P 2022. SQ.EnergyBalance: A model to calculate the temperature and energy
          balance of canopies, 1.0, Crop Model Repository, record crop2ml:0000000001. <br>
          This citation method follows the Force11 <a
              target="_blank"
              href="https://force11.org/info/software-citation-principles-published-2016/"
              class="text-decoration-none">Software Citation principles</a>.

        </p>

      </b-col>
    </b-row>
<!--    <div id="objective">-->

<!--      <div>-->
<!--        <p>-->
<!--        <center><b>-->
<!--          A repository of biophysical processes involved in crop growth and development-->
<!--        </b></center>-->
<!--        <p>-->
<!--          A short paragraph of Crop2ML project-->
<!--        </p>-->

<!--        <div class="row">-->
<!--          <div class="col-sm-6">-->
<!--            <h2> Recent Models </h2>-->

<!--            <ul class="myListStyle">-->
<!--              <a href="#/Repository">-->
<!--                <li>APSIM Phenology model component</li>-->
<!--              </a>-->
<!--            </ul>-->


<!--          </div>-->

<!--          <div class="col-sm-6">-->

<!--            <h2>-->
<!--              News-->
<!--            </h2>-->
<!--            <ul class="myListStyle">-->
<!--              <li>-->
<!--                <a href="#">-->
<!--                  Crop2ML Hackathon 2021-->
<!--                </a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->

<!--        </div>-->


<!--        <div>-->

<!--        </div>-->

<!--        <b-button variant="outline-success"> more models</b-button>-->


<!--        <p>Contacts : Cyrille MIDINGOYI (cyrille.midingoyi@inrae.fr),-->
<!--          Pierre MARTRE (pierre.martre@inrae.fr),-->
<!--          Christophe PRADAL (cristophe.pradal@cirad.fr)-->
<!--        </p>-->

<!--        <p>Développeur : Vincent ARMANT, INRAE</p>-->
<!--      </div>-->

<!--    </div>-->
  </div>


</template>
<script>


export default {
  name: 'About_HowToCite',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
