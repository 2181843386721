<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          Partners
        </h1>
      </b-col>
    </b-row>
        <b-row>
      <b-col>
        <b-img
            src="images/partners.png"
            fluid
        />
      </b-col>
    </b-row>
  </div>

</template>
<script>


export default {
  name: 'AMEI_Members',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
