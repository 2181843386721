<template>

  <div class="container">
    <b-row>
      <b-col>
        <h1 class="heading">
          Crop2ML Tools
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-list-group flush>
          <b-list-group-item class=" flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">PyCrop2ML</h5>
                </div>
              </div>
            </div>
            <p class="text-sm-left mb-0">
              <a
                  target="_blank"
                  href="https://github.com/AgriculturalModelExchangeInitiative/Pycrop2ml_ui"
                  class="text-decoration-none">PyCrop2ML</a> is an extensible Python library for defining and exchanging
              Crop2ML
              models. It provides tools to manage the life cycle of a Crop2ML package from its creation to its
              transformation in a crop modeling platform.
            </p>
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-center">
                  <b-img
                      src="images/Crop2ML_model_lifecycle.png"
                  />
                </div>
                <small>Crop2ML model lifecycle from the creation of a package to model transformation</small>
              </div>
            </div>

            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-start">
                  <h5 class="mb-1">PyCrop2ML allows to:</h5>
                </div>
              </div>
            </div>
            <b-list-group>
              <b-list-group-item class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">Verify a Crop2ML model</h5>
                </div>

                <p class="text-left mb-1">
                  This is ensured through a model parser based on the Crop2ML DTD.

                </p>

              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">Transform a Crop2ML ModelUnit to source code</h5>
                </div>

                <p class="text-left mb-1">
                  PyCrop2ML integrates CyMLT that generates model components that conform to the supported crop modeling
                  platform requirements.
                </p>

              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">Transform a CyML source code to various languages</h5>
                </div>

                <p class="text-left mb-1">
                  Regardless of Crop2ML model specifications, any CyML source code can also be transformed into the
                  target languages. This source code can be used as auxiliary functions for Crop2ML model development.
                </p>

              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">Transform source code to Jupyter notebook format</h5>
                </div>

                <p class="text-left mb-1">
                  Each ModelUnit source code generated can be translated as a cell of a Jupyter notebook, as well as,
                  each unit test, allowing its execution in Crop2ML JupyterLab environment.
                </p>

              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">Transform a Crop2ML ModelComposite</h5>
                </div>

                <p class="text-left mb-1">
                  A Crop2ML ModelComposite provided as a directed graph can be transformed to source code as a
                  sequential order of the submodels.

                </p>

              </b-list-group-item>

              <b-list-group-item class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">Visualize a ModelComposite</h5>
                </div>

                <p class="text-left mb-1">
                  Pycrop2ML provides a function to visualize a ModelComposite with the links between ModelUnits.
                </p>

              </b-list-group-item>
            </b-list-group>
          </b-list-group-item>
          <b-list-group-item class="flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-center">
                  <h5 class="mb-1">Cookiecutter-Crop2ML</h5>
                </div>
              </div>
            </div>
            <p class="text-sm-left mb-0">
              <a
                  target="_blank"
                  href="https://github.com/AgriculturalModelExchangeInitiative/cookiecutter-crop2ml"
                  class="text-decoration-none">Cookiecutter-Crop2ML</a> is a standardized template used to generate <a
                target="_blank"
                href="https://crop2ml.readthedocs.io/en/latest/user/package.html#how-to-add-a-crop2ml-package"
                class="text-decoration-none">Crop2ML package</a>
              structure for sharing crop models components between crop modeling platforms. The image below shows the
              directory structure of a model package as defined by Cookiecutter-Crop2ML.</p>

            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-center">
                  <b-img class="align-items-center"
                      src="images/structure_Crop2ML.png"
                  />
                </div>
                <small>Tree view of the structure of a Crop2ML model component package</small>
              </div>
            </div>
          </b-list-group-item>
          <b-list-group-item class="flex-column align-items-start py-4 px-4">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-center">
                  <h5 class="mb-1">CropMStudio</h5>
                </div>
              </div>
            </div>
            <p class="text-sm-left mb-0">
              <a
                  target="_blank"
                  href="https://crop2mlworkshop.readthedocs.io/en/latest/user/access.html"
                  class="text-decoration-none">CropMStudio</a> is a <a
                target="_blank"
                href="https://jupyterlab.readthedocs.io"
                class="text-decoration-none">JupiterLab</a>
              based environment that can be used to manage Crop2ML model life cycle (creation, edition,
              transformation, visualization, and execution) and create Crop2ML model packages.Another motivation to use
              JupyterLab is to make publication results reproducible in a shared environment based on the capacity to
              produce interactive and readable code documents.</p>

            <p class="text-sm-left mb-0">The use of CropMSutdio ensures the syntactic and semantic correctness of a
              model and that all unit tests
              are passed in the different programming languages.
              Model developers are thus encouraged to use CropMStudio to develop their model and to validate them before
              their submission to CropMRepository.
            </p>

            <p class="text-sm-left mb-0">There are three methods to run CropMStudio: Binder, local install with conda
              and with Docker container.
              Each of which is described <a
                  target="_blank"
                  href="https://crop2mlworkshop.readthedocs.io/en/latest/user/installation.html"
                  class="text-decoration-none">here</a>. We recommend using the MyBinder method as it does not require
              any local
              installation, but if you have difficulty opening the MyBinder instance, one of the other methods may be
              preferable.</p>

            <p class="text-sm-left mb-0">We are currently working to make CropMStudio accessible as a web service
              directly from
              CropMRepository.</p>

            <div class="d-flex w-100 justify-content-between">
              <div>
                <div class="d-flex w-100 align-items-center">
                  <b-img
                      src="images/CropMStudio.png"
                  />
                </div>
                <small>Screenshots of the modeling environment CropMStudio</small>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>

</template>
<script>


export default {
  name: 'Crop2ML_Tools',

  data() {
    return {}
  },

  components: {},

  async created() {

  },

  async mounted() {

  }

}
</script>

<style scoped>

ul.myListStyle {
  list-style-type: none;
}

#about {
  height: 80vh;
  width: 100%;
}

#recentModels {
  text-align: left;
}


</style>
